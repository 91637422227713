import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';

import InputFile from '@/components/contract/read/elements/InputFile/InputFile.vue';

export default {
	name: 'AddDirectAccessDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contractId: {
			type: [String, Number],
			required: false,
		},
		pages: {
			type: [String, Number],
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			currentFile: null,
			access_form: {
				page: null,
				type: null,
			},
			requiredRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (!newVal) {
				this.closeDialog();
			}
		},
	},
	methods: {
		...mapActions('contract', ['saveAccessDirect']),

		async save() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated) {
				this.loading = true;
				const data = {
					...this.access_form,
					contract_id: parseInt(this.contractId),
					page: parseInt(this.access_form?.page),
				};
				const { ok, response, error } = await this.saveAccessDirect(data);
				if (ok) {
					this.$emit('loadContract', response);
					this.dialog = false;
				} else {
					this.showError(error);
				}
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.form) {
					this.$refs.form.resetValidation();
				}
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		InputFile,
	},
};

import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'DisableContractBtn',
	props: {
		disabled: {
			type: Boolean,
			required: false,
		},
		contract: {
			type: [Object],
			required: false,
		},
	},
	data() {
		return {
			loading: false,
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		...mapActions('contract', ['updateContract']),
		
		async changeStatus() {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.sendUpdate();
			}
		},

		async sendUpdate() {
			try {
				this.loading = true;
				const payload = {
					id: this.contract.id,
					bodyParams: {
						...this.contract,
						flag_disabled: !this.disabled,
					},
				};
				await this.updateContract(payload);
				$EventBus.$emit('showSnack', 'success', 'Registro actualizado');
				this.$emit('loadContract');
			} catch (error) {
				this.showError(error?.response || error.toString());
			} finally {
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},
	},
	components: {},
};

import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import InputFile from '@/components/contract/read/elements/InputFile/InputFile.vue';
import ContractSheetAddendum from '@/components/contract/create/elements/ContractSheetAddendum/ContractSheetAddendum.vue';

export default {
	name: 'AddDocumentDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contractId: {
			type: [String, Number],
			required: false,
		},
		contract: {
			type: [Object],
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			currentFile: null,

			contract_document: {
				type: null,
				version: null,
				comment: null,
			},
			types: [
				{ value: 'LETTER', label: 'Carta' },
				{ value: 'ADDENDUM', label: 'Adenda ' },
				{ value: 'REPORT', label: 'Acta' },
				{ value: 'ANNEX', label: 'Anexo'}
			],

			fields: [],
		};
	},
	created() { },
	mounted() { },
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (!newVal) {
				this.closeDialog();
			}
		},
	},
	methods: {
		...mapActions('contract', [
			'uploadFile',
			'saveContractDocument',
			'saveUploadContract'
		]),

		async upload() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated && this.currentFile?.file) {
				this.loading = true;
				let formData = new FormData();
				formData.append('file', this.currentFile?.file, this.currentFile?.name);
				const { ok, response, error } = await this.uploadFile(formData);
				if (ok) {
					this.saveDocument(response?.data);
				} else {
					this.showError(error);
					this.loading = false;
				}
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Formulario');
			}
		},

		async saveDocument(file) {
			let fields = [];
			this.fields.forEach((group) => {
				group.items.forEach((item) => {
					fields.push({
						field_id: item.id,
						value: item.value,
					});
				});
			});
			const data = {
				contract_document: {
					...this.contract_document,
					contract_id: parseInt(this.contractId),
					file: file.file,
					job_id: file.jobId,
				},
				contract_document_field:
					this.contract_document.type == 'ADDENDUM' ? fields : null,
			};

			const { ok, response, error } = await this.saveContractDocument(data);
			if (ok) {
				this.$emit('loadContract', response);
				this.dialog = false;
				if (this.$refs.addForm) this.$refs.addForm.reset();
			} else {
				this.showError(error);
			}
			this.loading = false;
		},

		async saveUploadDocument() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated && this.currentFile?.file) {
				let fields = [];
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						fields.push({
							field_id: item.id,
							value: item.value,
						});
					});
				});

				this.loading = true;
				let formData = new FormData();

				formData.append('file', this.currentFile?.file, this.currentFile?.name);
				formData.append('comment', this.contract_document.comment);
				formData.append('contract_id', parseInt(this.contractId));
				formData.append('type', this.contract_document.type);
				formData.append('version', this.contract_document.version);
				if (this.contract_document.type === 'ADDENDUM') formData.append('contract_document_field', JSON.stringify(fields));

				const { ok, response, error } = await this.saveUploadContract(formData);
				if (ok) {
					this.$emit('loadContract', response);
					this.dialog = false;
					if (this.$refs.addForm) this.$refs.addForm.reset();
				} else {
					this.showError(error);
				}
				this.loading = false;
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Formulario');
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.addForm) this.$refs.addForm.reset();
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		InputFile,
		ContractSheetAddendum,
	},
};

<template>
	<show-content />
</template>
<script>
import ShowContent from '@/components/contract/read/ReadContract/ReadContract.vue';

export default {
	name: 'ReadContractView',
	components: { ShowContent },
};
</script>

import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'DeleteDocumentBtn',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contract: {
			type: [Object],
			required: false,
		},
	},
	data() {
		return {
			loading: false,
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		...mapActions('contract', ['deleteContract']),

		async removeContract() {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro de que quieres eliminar?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				this.loading = true;
				const { ok, error } = await this.deleteContract(this.contract.id);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', 'Registro eliminado');
					this.$router.push({ name: 'ContractSearch' });
				} else {
					this.showError(error);
				}
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},
	},
	components: {},
};

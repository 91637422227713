import { mapState } from 'vuex';

import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';

export default {
	name: 'ContractSheetAddendum',
	props: {
		value: {
			type: Array,
			required: true,
		},
		responses: {
			type: Array,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		contractId: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			// fields: [],
			loadingData: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			loading: false,

			dialog: false,
		};
	},
	created() {
		this.setValues();
	},
	mounted() {},
	computed: {
		...mapState('document', ['template']),
		fields: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		myResponses: {
			get() {
				return this.responses || false;
			},
			set(value) {
				this.$emit('setResponses', value);
			},
		},
	},
	watch: {},
	methods: {
		setValues() {
			let fields = [...(this.template?.fields || [])];
			if (
				Array.isArray(fields) &&
				Array.isArray(this.myResponses) &&
				this.myResponses.length > 0
			) {
				fields = fields.map((group) => {
					let items = group.items.map((item) => {
						const found = this.myResponses.find(
							(res) => res.field_id == item.id
						);
						return { ...item, value: found ? found.value : null };
					});
					return { ...group, items };
				});
			}
			this.fields = fields;
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
	},
};

import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';

import InputFile from '@/components/contract/read/elements/InputFile/InputFile.vue';

export default {
	name: 'AddVersionDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contractId: {
			type: [String, Number],
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			currentFile: null,
			version_form: {
				version: null,
				comment: null,
			},
			requiredRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (!newVal) {
				this.closeDialog();
			}
		},
	},
	methods: {
		...mapActions('contract', ['uploadFile', 'saveVersion']),

		async upload() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated &&  this.currentFile?.file) {
				this.loading = true;
				let formData = new FormData();
				formData.append('file', this.currentFile?.file, this.currentFile?.name);
				const { ok, response, error } = await this.uploadFile(formData);
				if (ok) {
					this.save(response?.data);
				} else {
					this.showError(error);
					this.loading = false;
				}
			}
		},

		async save(file) {
			const data = {
				...this.version_form,
				contract_id: parseInt(this.contractId),
				file: file.file,
				job_id: file.jobId,
			};
			const { ok, response, error } = await this.saveVersion(data);
			if (ok) {
				this.$emit('loadContract', response);
				this.dialog = false;
			} else {
				this.showError(error);
			}
			this.loading = false;
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},
		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.form) {
					this.$refs.form.resetValidation();
				}
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		InputFile,
	},
};

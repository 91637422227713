import { render, staticRenderFns } from "./AddDirectAccessDialog.vue?vue&type=template&id=92308d94&"
import script from "./AddDirectAccessDialog.js?vue&type=script&lang=js&"
export * from "./AddDirectAccessDialog.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import { $EventBus } from '@/main';

export default {
	name: 'InputDocument',
	props: {
		value: {
			type: Object,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			defaultButtonText: null,
			isSelecting: false,
		};
	},
	created() {},
	mounted() {},
	computed: {
		document: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		buttonText() {
			return this.defaultButtonText || this.label;
		},
	},
	watch: {},
	methods: {
		onButtonClick() {
			this.isSelecting = true;
			window.addEventListener(
				'focus',
				() => {
					this.isSelecting = false;
				},
				{ once: true }
			);

			this.$refs.uploader.click();
		},
		hasExtension(file) {
			let exts = ['.pdf', '.zip'];
			let fileName = file?.name;
			const parts = fileName.split('.');
			const extension = parts.pop().toLowerCase();
			const baseName = parts.join('.');
			fileName = baseName + '.' + extension;
			return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(
				fileName
			);
		},
		onFileChanged(e) {
			const files = [...e.target.files];
			if (Array.isArray(files)) {
				files.forEach((file) => {
					if (this.hasExtension(file)) {
						this.defaultButtonText = file.name;
						this.document = {
							name: file.name,
							file: file,
							url: URL.createObjectURL(file),
						};
					} else {
						$EventBus.$emit(
							'showSnack',
							'warning',
							'Seleccione el formato de archivo correcto'
						);
					}
				});
			}
			// do something
		},
	},
	components: {},
};

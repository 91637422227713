import { $EventBus } from '@/main';
import { updateContract } from '@/store/modules/contract/service/contract.service';

export default {
	name: 'UpdateTitleDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contract: {
			type: [Object],
			required: true,
		},
	},
	data() {
		return {
			name: '',
			valid: true,
			dialog: false,
			loadingSave: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {
		myModel: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.name = this.contract?.name;
			}
		},
	},
	methods: {
		showAlert(msmError, color = 'error') {
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.resetValidation();
				}
			});
		},

		async validForm() {
			const isvalidated = this.$refs.contractForm.validate();
			if (isvalidated) {
				try {
					this.loadingSave = true;
					const id = this.contract?.id;
					const bodyParams = { name: this.name };
					const response = await updateContract({
						id,
						bodyParams,
					});
					this.showAlert(response?.message || 'Guardado', 'success');
					this.$emit('update');
					this.cleanForm();
				} catch (error) {
					this.showAlert(error?.response?.data?.message || error.toString());
				} finally {
					this.loadingSave = false;
				}
			}
		},

		cleanForm() {
			this.$nextTick(() => {
				this.name = null;
				if (this.$refs.contractForm) this.$refs.contractForm.reset();
			});
		},
	},
	components: {},
};

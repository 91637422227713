import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';

import InputFile from '@/components/contract/read/elements/InputFile/InputFile.vue';
import moment from 'moment';

export default {
	name: 'ResponsibleDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		contractId: {
			type: [String, Number],
			required: false,
		},
		mode: {
			type: [String],
			required: false,
		},
		responsible: {
			type: [Object],
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			currentFile: null,
			responsible_form: {
				user_id: null,
				validity_from: null,
				validity_to: null,
				type: null,
				indefinite: false,
			},
			requiredRule: [(v) => !!v || 'Campo requerido'],
			roles: [
				{ value: 'USER', text: 'Usuario' },
				{ value: 'MODERATOR', text: 'Moderador' },
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		isShow() {
			const form = this.responsible_form;
			return !form.indefinite;
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				if (this.mode === 'UPDATE') {
					this.responsible_form = {
						...this.responsible,
						indefinite: !this.responsible?.validity_to,
					};
				} else {
					this.responsible_form = {
						user_id: null,
						validity_from: null,
						validity_to: null,
						indefinite: false,
					};
					if (this.$refs.responsibleForm) {
						this.$refs.responsibleForm.resetValidation();
					}
				}
			} else {
				this.closeDialog();
			}
		},
		'responsible_form.indefinite'(newVal) {
			if (newVal) {
				this.responsible_form.validity_from = moment().format('YYYY-MM-DD');
				this.responsible_form.validity_to = null;
			}
		},
	},
	methods: {
		...mapActions('responsible', ['saveResponsible', 'updateResponsible']),
		...mapActions('contract', ['getContract']),

		async save() {
			const isvalidated = this.$refs.responsibleForm.validate();
			if (isvalidated) {
				this.loading = true;
				let responseApi = null;
				if (this.mode === 'CREATE') {
					const data = {
						...this.responsible_form,
						user_id: parseInt(this.responsible_form.user_id),
						contract_id: parseInt(this.contractId),
					};
					responseApi = await this.saveResponsible(data);
				} else if (this.mode === 'UPDATE') {
					const data = {
						id: this.responsible_form?.id,
						body: {
							...this.responsible_form,
							user_id: parseInt(this.responsible_form.user_id),
							contract_id: parseInt(this.contractId),
						},
					};
					responseApi = await this.updateResponsible(data);
				}

				const { ok, response, error } = responseApi;
				if (ok) {
					this.getContract(this.contractId);
					$EventBus.$emit(
						'showSnack',
						'success',
						response?.message[0] || '¡Guardado!'
					);
					this.dialog = false;
				} else {
					this.showError(error);
				}
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.responsibleForm) {
					this.$refs.responsibleForm.resetValidation();
				}
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		InputFile,
	},
};

import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import ResponsibleDialog from '@/components/contract/read/elements/ResponsibleDialog/ResponsibleDialog.vue';

export default {
	name: 'ResponsiblesSection',
	props: {
		value: {
			type: Array,
			required: false,
		},

		permissions: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			dialog: false,
			selected: null,
			modeDialog: null,
			responsibles_headers: [
				{
					text: 'Nombre',
					value: 'user_id',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Tipo',
					value: 'type',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Fecha inicio',
					value: 'validity_from',
					sortable: false,
					align: 'center',
					class: 'primary--text',
				},
				{
					text: 'Fecha fin',
					value: 'validity_to',
					sortable: false,
					align: 'center',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'right',
					class: 'primary--text',
				},
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),

		responsibles: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		dialog(val) {
			if (!val) this.selected = null;
		},
	},
	methods: {
		...mapActions('contract', ['getContract']),
		...mapActions('responsible', ['deleteResponsible']),
		onFileChanged() {
			$EventBus.$emit(
				'showSnack',
				'warning',
				'Seleccione el formato de archivo correcto'
			);
		},

		formatDate(date) {
			moment.locale('es');
			return date ? moment(date).format('LL') : null;
		},

		getUserName(id) {
			const user = (this.usersActive || []).find((user) => user.id == id);
			return user?.fullName || '';
		},

		openDialog(item) {
			if (item) {
				this.selected = item;
				this.modeDialog = 'UPDATE';
			} else {
				this.modeDialog = 'CREATE';
			}
			this.dialog = true;
		},

		async deleteItem(id) {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro de que quieres eliminar?',
				text: null,
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#0039a6',
				confirmButtonColor: '#e0e0e0',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
			});
			if (isConfirmed) {
				const { ok, response, error } = await this.deleteResponsible(id);
				if (ok) {
					this.getContract(this.$route.params.documentId);
					$EventBus.$emit(
						'showSnack',
						'success',
						response?.message[0] || '¡Guardado!'
					);
					this.dialog = false;
				} else {
					this.showError(error);
				}
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		getRole(expr) {
			switch (expr) {
				case 'USER':
					return 'Usuario';
				case 'MODERATOR':
					return 'Moderador';
				default:
					return '';
			}
		},
	},
	components: {
		ResponsibleDialog,
	},
};
